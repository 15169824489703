@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");

li {
  list-style: none;
  border-radius: 100%;
  color: white;
  width: 1rem;
  height: 1rem;
  font-size: 0.65rem;
  display: flex;
  justify-content: center;
  align-content: center;
}

a:link {
  color: #2b898b;
  text-decoration: none;
}

a:visited {
  color: #2b898b;
  text-decoration: none;
}

.A-Center {
  align-items: center;
}

.Arrow-Left {
  transform: rotate(90deg);
  height: 1rem;
  width: 1rem;
  position: relative;
  left: 0;
  background-color: #f6f7f7;
  padding: 0.5rem;
  border-radius: 50%;
  color: darkgray;
}

.Arrow-Left-Btn {
  position: relative;
  left: 1.75rem;
  background: transparent;
}

.Arrow-Right-Btn {
  background: transparent;
  position: relative;
  right: 1.75rem;
}

.Arrow-Right {
  transform: rotate(-90deg);
  height: 1rem;
  width: 1rem;
  position: relative;
  right: 0;
  background-color: #f6f7f7;
  padding: 0.5rem;
  border-radius: 50%;
  color: darkgray;
}

.Badge {
  flex: 1 0 calc(25% - 10px);
}

.Badge-Icon {
  background-color: #2b898b;
  color: white;
  border-radius: 0.25rem;
  margin: 0.25rem 0.1rem;
}

.Badge-Title {
  background-color: #dfeeee;
  padding: 1rem;
  margin-top: 1rem;
}

.Background-LGreen {
  background-color: #dfeeee;
}

.Box-Shadow {
  box-shadow: 0 7px 20px 0 rgb(0 0 0 / 30%);
}

button {
  background-color: transparent;
  border: none;
}

.bord-5 {
  border-radius: 0.5rem;
}

.bord-15 {
  border-radius: 1.5rem;
}

.Dark-Gray {
  color: darkgray;
}

.Div-Parent-Badge {
  display: flex;
  flex-wrap: wrap;
}

#Div-Selected {
  border-top: dotted #2b898b 0.05rem;
  border-left: dotted #2b898b 0.05rem;
  border-right: dotted #2b898b 0.05rem;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}

#Div-Not-Selected {
  border-bottom: dotted #2b898b 0.05rem;
}

.Column {
  display: flex;
  flex-direction: column;
}

.Donut-Width {
  width: 5rem;
}

.Ingredient {
  height: 1rem;
  margin-bottom: 0.5rem;
}

.Divider-Line {
  margin: 1rem 0 1rem 0;
  width: 45%;
}

.Disclaimer-Text {
  margin: 1rem 0.2rem 0.1rem 0.2rem;
  font-size: 0.5rem;
}

.Filter-Max-Height {
  max-height: 30vh;
  overflow: auto;
}

.Green {
  color: #2b898b;
}

.Hidden {
  display: none;
}

.kitchen-div {
  max-width: 120px;
  margin: 0.25rem;
}

.link-card-bkgrd {
  border-radius: 0.5rem;
  background-color: white;
}

.kitchen-tool {
  min-height: 250px;
}

.kitchen-tools {
  max-width: 100%;
  overflow: auto;
}

.kitchen-tool-name {
  min-height: 48px;
  flex-wrap: wrap;
  align-content: center;
  text-align: center;
}

.Large-Logo {
  width: 17.5rem;
  border-radius: 0.25rem;
}

.Linked-Titles {
  margin: 1rem 1rem 1rem 0.5rem;
}

.Linked-Sections {
  background: #f790284a;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

#Logo {
  width: 90%;
  height: 90%;
  margin: 1rem;
  display: flex;
  justify-content: center;
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  animation-name: spinning;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@media only screen and (min-width: 400px) {
  .loading {
    max-width: 25%;
  }
}

.marg-l-n-2 {
  margin-left: -0.2rem;
}

.marg-0 {
  margin: 0;
}

.marg-5 {
  margin: 0.5rem;
}

.marg-25 {
  margin: 0.25rem;
}

.marg-b-1 {
  margin-bottom: 1rem;
}

.marg-left-5 {
  margin-left: 0.5rem;
}

.marg-right-5 {
  margin-right: 0.5rem;
  border-radius: 100%;
}

.marg-1 {
  margin: 1rem;
}

.marg-t-1 {
  margin-top: 1rem;
}

.marg-t-3 {
  margin-top: 3rem;
}

.max-h-1 {
  max-height: 1rem;
}

.max-h-25 {
  max-height: 25rem;
}

.max-w-15 {
  max-width: 15rem;
  overflow: auto;
}

.max-w-25 {
  max-width: 25%;
}

.m1-5 {
  margin: 1.5rem;
}

.Medium-Font {
  font-size: 1rem;
}

#Menu-Logo {
  width: 90%;
}

.min-width-50 {
  min-width: 50%;
}

.Not-Selected {
  color: lightgrey;
  margin: 0;
}

.Hide {
  width: 0;
  height: 0;
}

.Hide-Float {
  width: 500rem;
  background-color: #ffffff;
}

.p0 {
  padding: 0;
}

.P-T-R-L-5 {
  padding: 0.5rem 0.5rem 0 0.5rem;
}

.P-R-B-L-5 {
  margin: -0.5rem 0 0 1rem;
  padding: 0 0.5rem 0.5rem 0;
}

.p1 {
  padding: 1rem;
}

.pt1 {
  padding-top: 1rem;
}

.p25p2 {
  padding: 0.25rem 2rem;
}

.Progress-Bar-To-Do {
  position: relative;
  top: -1.5rem;
  right: -0.95rem;
  width: 1rem;
  color: darkgray;
  margin-right: 0.5rem;
}

.Progress-Bar-Done {
  position: relative;
  top: -1.5rem;
  right: -0.95rem;
  width: 1rem;
  color: #2b898b;
  margin-right: 0.5rem;
}

.Recipe-Card {
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Recipe-Details {
  background-color: #ffffff;
  margin: 2rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 5px 5px rgb(232, 232, 232);
}

.Sub-Heading {
  font-family: "Sacramento", cursive;
  color: #2b898b;
  font-size: 1.5rem;
  font-weight: 250;
}

.Relative-Pos {
  position: relative;
  right: -0.99rem;
  top: -0.25rem;
}

.Row {
  display: flex;
}

.Search-By-Name {
  height: 2rem;
  padding: 1rem;
  border: none;
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  box-shadow: 0 7px 20px 0 rgb(0 0 0 / 30%);
  width: 85%;
}

.faking {
  background-color: #dfeeee;
  border-bottom-right-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  box-shadow: 0 7px 20px 0 rgb(0 0 0 / 30%);
  color: gray;
  height: 2rem;
  padding: 0.1rem 1rem;
  width: 15%;
}

.Float {
  position: sticky;
  top: 95%;
  display: flex;
  justify-content: flex-end;
}

.Selected {
  color: #212f37;
  margin: 0;
}

.Shrink-Header {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: block;
  line-height: 1.2em; /* a */
  /* max-height: 2em; */
  margin: 0.5rem 1rem 0.75rem 1rem;
}

.Space-E {
  justify-content: space-evenly;
}

.Space-B {
  justify-content: space-between;
}

.Small-Font {
  font-size: 0.5rem;
  margin: 0.1rem 0.2rem;
}

.Small-Logo {
  height: 5rem;
  width: 5rem;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

.Small-Badge {
  height: 1rem;
  width: 1rem;
  padding: 0.02rem;
}

.Step {
  border-right: dotted #2b898b 0.05rem;
  border-left: dotted #2b898b 0.05rem;
  border-bottom: dotted #2b898b 0.05rem;
  margin-top: -0.45rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.Step-Card {
  height: 20rem;
  padding: 2rem 1rem 1rem 1rem;
  margin: 1rem 0.5rem 0.5rem 0.5rem;
  background-color: #dfeeee;
  width: 15.5rem;
}

.Step-Card-Container {
  min-width: 18rem;
}

.Step-Detail {
  position: relative;
  top: 10%;
}

.Justify-Center {
  display: flex;
  justify-content: center;
}

.Width-50 {
  width: 50%;
}

.Width-100 {
  width: 100%;
}

.Width-1-5 {
  width: 1.65rem;
}

.Width-1 {
  min-width: 1.15rem;
}

.Width-4 {
  max-width: 4rem;
}

.Width-10 {
  width: 10rem;
}

.Width-16 {
  width: 16rem;
}

.Width-25 {
  width: 120%;
}

.Done {
  background-color: #2b898b;
}

.To-Do {
  background-color: darkgray;
}

.Hide-Step {
  display: none;
}

.Block {
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.First-LI {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #2b898b;
}

.Float {
  position: sticky;
  top: 95%;
  display: flex;
  justify-content: flex-end;
}

.Last-LI {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.Width-90 {
  width: 90%;
}

.Width-100 {
  width: 100%;
}

#Progress-Bar-UL {
  margin: 0;
  padding: 0;
  display: flex;
}

ul {
  padding: 0;
  display: flex;
}

@media  only screen and (min-width: 400px){
  .results-btn {
   display: flex;
   justify-content: center;
  }
  
}
