.accordion-button-override {
  position: absolute;
  right: 1.4rem;
  top: 1.5rem;
  background-color: white;
  color: darkgray;
  height: 1.25rem;
  width: 1.25rem;
  z-index: 1000;
}

.accordion-button:not(.collapsed) {
  background-color:  white;
}

.accordion-body-sort {
  display: flex;
  justify-content: space-around;
}

@media only screen and (min-width: 400px) {
  .accordion-body {
    display: flex;
    justify-content: space-evenly;
  }
}