@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");

.accordion {
  padding: 0.5rem;
  max-height: 50vh;
  overflow: auto;
  margin-bottom: 0.5rem;
}

.App {
  height: 100vh;
  background-color: #f6f7f7;
}

.App-header {
  height: 12%;
  background-color: #2b898b;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 7px 20px 0 rgb(0 0 0 / 30%);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.App-header-expanded {
  background-color: #2b898b;
  display: flex;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 7px 20px 0 rgb(0 0 0 / 30%);
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  justify-content: center;
}

body {
  color: #212f37;
  background-color: #f6f7f7;
}

.Logo-Home-Btn {
  max-height: 100%;
  margin: 0.25rem;
  max-width: 5rem;
}

.Filter {
  justify-content: flex-end;
  color: white;
}

.Filter::after {
  color: red;
}

button {
  background-color: transparent;
}

img {
  max-height: 100%;
  max-width: 100%;
}

.Menu-Header {
  color: white;
  font-family: "Sacramento", cursive;
  font-weight: normal;
  margin-block-start: 1em;
}

.Menu-Options {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Menu-Option {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 1rem 1rem 1rem;
}

#root {
  background-color: #f6f7f7;
}

.Row {
  display: flex;
}

#Section-List {
  background-color: #f6f7f7;
  padding: 5rem 0;
}

.TLDR {
  font-family: var(--bs-body-font-family);
}
